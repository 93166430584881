export default function mediaReel() {
  const buttons = document.querySelectorAll(".media-reel__arrow");

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const blockId = button.parentElement.getAttribute("data-block");
      const reel = document.querySelector(`.media-reel[data-block="${blockId}"] .media-reel__reel`);
      const currentIndex = parseInt(reel.getAttribute("data-active-index"), 10) || 0;
      const slides = document.querySelectorAll(
        `.media-reel[data-block="${blockId}"] .media-reel__content > .media-reel__details`,
      );
      const nextButton = document.querySelector(`.media-reel[data-block="${blockId}"] .media-reel__arrow--next`);
      const previousButton = document.querySelector(
        `.media-reel[data-block="${blockId}"] .media-reel__arrow--previous`,
      );

      slides.forEach((slide) => {
        slide.classList.remove("active");
        slide.setAttribute("aria-hidden", "true");
      });

      const newActiveIndex = button.classList.contains("media-reel__arrow--previous")
        ? currentIndex - 1
        : currentIndex + 1;

      if (newActiveIndex === 0) {
        previousButton.disabled = true;
      } else {
        previousButton.disabled = false;
      }

      if (newActiveIndex === slides.length - 1) {
        nextButton.disabled = true;
      } else {
        nextButton.disabled = false;
      }

      slides[newActiveIndex].classList.add("active");
      slides[newActiveIndex].setAttribute("aria-hidden", "false");

      reel.setAttribute("data-active-index", newActiveIndex);
      reel.style.setProperty("--active-index", newActiveIndex);
    });
  });
}
