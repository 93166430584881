export default function newsBanner() {
  const newsBannerEl = document.getElementById("newsBanner");
  const newsBannerClose = document.querySelector(".news-banner__close");
  const navigationBurger = document.querySelector(".navigation__burger");
  const bannerId = newsBannerEl.dataset.bannerId;

  function updateNav() {
    const dismissed = localStorage.getItem(`news-banner-dismissed-${bannerId}`) === "true";
    if (dismissed) {
      newsBannerEl.style.display = "none";
      navigationBurger.style.marginTop = "0px";
      document.body.style.paddingTop = "0px";
    } else {
      newsBannerEl.style.display = "block";
      adjustBurgerMargin();
      adjustBodyPadding();
    }
  }

  function dismissBanner() {
    localStorage.setItem(`news-banner-dismissed-${bannerId}`, "true");
    updateNav();
  }

  function adjustBurgerMargin() {
    if (newsBannerEl.style.display !== "none") {
      const bannerHeight = newsBannerEl.offsetHeight;
      navigationBurger.style.marginTop = `${bannerHeight}px`;
    } else {
      navigationBurger.style.marginTop = "0px";
    }
  }

  function adjustBodyPadding() {
    if (newsBannerEl.style.display !== "none") {
      const bannerHeight = newsBannerEl.offsetHeight;
      document.body.style.paddingTop = `${bannerHeight}px`;
    } else {
      document.body.style.paddingTop = "0px";
    }
  }

  if (newsBannerClose) {
    newsBannerClose.addEventListener("click", dismissBanner);
  }

  window.addEventListener("resize", () => {
    adjustBurgerMargin();
    adjustBodyPadding();
  });

  document.body.addEventListener("transitionend", () => {
    adjustBurgerMargin();
    adjustBodyPadding();
  });
  document.body.addEventListener("click", () => {
    adjustBurgerMargin();
    adjustBodyPadding();
  });

  updateNav();
  adjustBurgerMargin();
  adjustBodyPadding();
}
