export default function clusteredCollection() {
  const buttons = document.querySelectorAll(".clustered-collection__cluster-button");

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const blockId = button.parentElement.getAttribute("data-block");
      const clusterName = button.parentElement.getAttribute("data-cluster");
      const clusterButtons = button.parentElement.querySelectorAll(".clustered-collection__cluster-button");
      const relatedList = document.querySelector(
        `.clustered-collection__clusters[data-block="${blockId}"] .clustered-collection__list[data-content="${clusterName}"]`,
      );

      clusterButtons.forEach((clusterButton) => clusterButton.classList.toggle("active"));
      relatedList.classList.toggle("open");
    });
  });
}
