const MOBILE_WIDTH = 800;

export default function openAndCloseNavigationOverlay() {
  const nav = document.querySelector(".navigation__nav");
  const menuButton = document.querySelector(".navigation__burger");
  const body = document.querySelector("body");

  if (!nav || !menuButton) {
    return;
  }

  menuButton.addEventListener("click", () => {
    if (window.innerWidth < MOBILE_WIDTH) {
      nav.classList.toggle("navigation__nav--active");
      if (nav.classList.contains("navigation__nav--active")) {
        body.style.height = "100%";
        body.style.overflow = "hidden";
      } else {
        body.style.height = "";
        body.style.overflow = "";
      }
    }
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > MOBILE_WIDTH) {
      nav.classList.remove("navigation__nav--active");
      body.style.height = "";
      body.style.overflow = "";
    }
  });
}
