export default function accordion() {
  const accordions = document.querySelectorAll(".accordion__items");

  accordions.forEach((accordionEl) => {
    const accordionItems = accordionEl.querySelectorAll(".accordion__item-header");
    accordionItems.forEach((accordionItem) => {
      accordionItem.parentElement.classList.toggle("accordion__item--collapsed");
      accordionItem.addEventListener("click", () => {
        accordionItem.parentElement.classList.toggle("accordion__item--collapsed");
      });
    });
  });
}
