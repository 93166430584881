export default function cookieBar() {
  const googleAnalyticsId = document.querySelector("body").dataset.googleAnalyticsId;
  const cookiebar = document.querySelector(".cookiebar");

  window[`ga-disable-${googleAnalyticsId}`] = true;
  window.dataLayer = window.dataLayer || [];

  if (cookiebar) {
    const cookieName = "cookiebarAccepted";

    const existingCookie = checkForExistingCookie(cookieName);

    if (!existingCookie) {
      cookiebar.classList.add("cookiebar--active");
    }

    if (existingCookie?.includes("true")) {
      trackPageInGoogleAnalytics();
    }

    document.querySelector(".cookiebar__button--agree").addEventListener("click", () => {
      document.cookie = `${cookieName}=true`;
      closeCookiebar(cookiebar);
      trackPageInGoogleAnalytics();
    });

    document.querySelector(".cookiebar__button--reject").addEventListener("click", () => {
      document.cookie = `${cookieName}=false`;
      closeCookiebar(cookiebar);
    });
  }

  function gtag(...args) {
    window.dataLayer.push(args);
  }

  function trackPageInGoogleAnalytics() {
    window[`ga-disable-${googleAnalyticsId}`] = false;
    gtag("js", new Date());
    gtag("config", googleAnalyticsId);
  }
}

function closeCookiebar(cookiebar) {
  cookiebar.classList.remove("cookiebar--active");
}

function checkForExistingCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  return cookieArray.find((cookie) => cookie.includes(name));
}
