export default function homeStage() {
  const navButtons = document.querySelectorAll(".media-toggle__nav-button");

  navButtons.forEach((navButton) => {
    navButton.addEventListener("click", () => {
      const blockId = navButton.getAttribute("data-block");
      const slideIndex = navButton.getAttribute("data-slide-index");
      const navigation = document.querySelector(
        `.media-toggle__slider[data-block="${blockId}"] .media-toggle__navigation`,
      );
      const slides = document.querySelectorAll(`.media-toggle__slider[data-block="${blockId}"] .media-toggle__slide`);
      const blockNavButtons = document.querySelectorAll(
        `.media-toggle__slider[data-block="${blockId}"] .media-toggle__nav-button`,
      );

      slides.forEach((slide) => {
        slide.classList.remove("media-toggle__slide--active");
        slide.setAttribute("aria-hidden", "true");
      });

      blockNavButtons.forEach((button) => {
        button.classList.remove("media-toggle__nav-button--active");
      });

      slides[slideIndex].classList.add("media-toggle__slide--active");
      slides[slideIndex].setAttribute("aria-hidden", "false");

      navigation.style.setProperty("--active-index", slideIndex);
      navButton.classList.add("media-toggle__nav-button--active");
    });
  });
}
