export default function newsletterSignup() {
  const footerForm = document.getElementById("newsletter-form");
  footerForm.addEventListener("submit", subscribeToNewsletter);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function subscribeToNewsletter(e) {
  e.preventDefault();
  const form = e.target;
  const email = document.getElementById("newsletter-email").value;
  const feedback = form.querySelector(".footer__form-feedback");
  const button = e.submitter;
  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const source = urlParams.source || document.referrer || window.location.href;

  if (email && validateEmail(email)) {
    button.disabled = true;
    fetch("https://api.nlx.ai/subscribe", {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
        "x-api-key": "HsTxF2UQYu7QlivUZXnefaTOmXu5oeBL8R4h0Rl4",
      },
      body: JSON.stringify({ email, source }),
    }).then((res) => {
      feedback.textContent = res.ok
        ? "Thank you for subscribing to the NLX newsletter."
        : "Something went wrong. Please try again.";
      feedback.classList.add("footer__form-feedback--visible");
      button.disabled = false;
    });
  }
}
