export default function showAndHideDropdownMenuItemsOnHover() {
  const dropdownElements = Array.from(document.querySelectorAll(".navigation__navbar-item"));

  dropdownElements.forEach((dropdownElement) => {
    dropdownElement.addEventListener("mouseenter", () => {
      dropdownElement.focus();
    });

    dropdownElement.addEventListener("click", () => {
      dropdownElement.focus();
    });

    dropdownElement.addEventListener("mouseleave", () => {
      document.activeElement.blur();
    });
  });
}
